import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  readonly testimonials = [
    {
      message:
        'Great service with well mannered staff on both separate occasions. Beef/lamb kabab always perfect, in taste, form, quality and quantity. Kababs are actually the best I have had.',
      userName: 'Laura Murray',
    },
    {
      message:
        'Thank you so much for the wonderful Kehab. One of the best Kebabs I have tasted. Plus the owner and his staff member are very nice.  I will be a regular. I also like the fact they are opened at 9am each day which suits a busy person',
      userName: 's X',
    },
    {
      message:
        'It was super busy when we went in however we were served quickly and our food didn’t take long. Amazing and friendly customer service. Will definitely be back soon! :)',
      userName: 'Priscilla Skinner',
    },
    {
      message:
        'First visit and am really surprised. Huge food amounts and good prices. Staff were lovely and service excellent. Good job guys 😊',
      userName: 'Helga Phipps',
    },
    {
      message: 'Excellent experience in-store in first visit',
      userName: 'Krunal Patel',
    },
  ];

  feedbackSlides: OwlOptions = {
    items: 1,
    margin: 0,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
  };
}
