import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IOfferItem } from '../../../interfaces/offer-item.interface';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent {
  offers: IOfferItem[] = [
    {
      title: 'Kebabs',
      image: 'assets/img/offers/offer1.jpg',
    },
    {
      title: 'Make it a meal',
      image: 'assets/img/offers/offer2.jpg',
    },
    {
      title: 'Pizzas',
      image: 'assets/img/offers/offer4.jpg',
    },
    {
      title: 'Turkish pride',
      image: 'assets/img/offers/offer5.jpg',
    },
    {
      title: 'Snack Pack',
      image: 'assets/img/offers/offer6.jpg',
    },
  ];

  offerSlides: OwlOptions = {
    margin: 25,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
}
