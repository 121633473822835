import { baseConfig } from './base-config';
import { IConfig } from './config.interface';

/**
 * Client specific overrides
 */
export const config: IConfig = {
  ...baseConfig,
  //! All client specific override needs to be applied after here.

  /**
   * Enable this to use default shared api hosted at api.storefront.readyorder.com.au
   */
  apiSubDomainPrefix: null,

  appName: 'IstanbulKebabAndPizza',

  appSubDomainPrefix: 'www',

  appDevSubDomainPrefix: 'storefront-dev',

  repository: 'ReadyOrder_Istanbul',

  domainName: 'istanbulkebab.com.au',

  mapRootDomain: true,

  storefront: {
    contact: {
      email: [] as { label: string; value: string }[],
      phone: [{ label: '(07) 5432 8007', value: '0754328007' }],
      address: '96-110 Bellmere Road, Bellmere Queensland 4510',
      googleLink: 'https://maps.app.goo.gl/aWhCmi9CUm1C6vVt8',
    },
    openingHours: {
      week: { startTime: '9:00AM', endTime: '9:00PM' },
      weekend: { startTime: '9:00AM', endTime: '8:00PM' },
    },
    restaurantId: 'YwzlEs85VuVEwbQ1wZ',
    gloriaFoodCuid: '4d4c5f69-4ddf-4af4-b08a-13c89307fd94',
    gloriaFoodRuid: '3597cbfc-5b70-467f-b7e1-6c153cc3cbe3',
    gloriaFoodUrl: 'https://pos.globalfoodsoft.com',
  },

  awsMailHostingProvider: {
    ...baseConfig.awsMailHostingProvider,
    emailSubject: 'New Website enquiry',
    emailToAddress: 'kp5453@yahoo.com',
  },
};
