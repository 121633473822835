<div class="features-area ptb-100 pb-0">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-features">
          <div class="icon">
            <i class="icofont-soft-drinks"></i>
          </div>
          <div class="features-content">
            <h3>Cold Drinks</h3>
            <p>Discover our wide selection of cold drinks.</p>
          </div>
        </div>
        <div class="single-features">
          <div class="icon">
            <i class="icofont-box"></i>
          </div>
          <div class="features-content">
            <h3>Take away</h3>
            <p>Order your favorite food and pick it up at our restaurant.</p>
          </div>
        </div>
        <div class="single-features">
          <div class="icon">
            <i class="icofont-chicken-fry"></i>
          </div>
          <div class="features-content">
            <h3>Spicy flavours</h3>
            <p>Experience the bold and fiery flavors of our spicy dishes.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="features-img">
          <img src="assets/img/features/features-img1.jpg" alt="features-img" />
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-features">
          <div class="icon">
            <i class="icofont-food-basket"></i>
          </div>
          <div class="features-content">
            <h3>Salubrious Snack packs</h3>
            <p>
              Our snack packs are the perfect choice for a quick and healthy
              meal.
            </p>
          </div>
        </div>
        <div class="single-features">
          <div class="icon">
            <i class="icofont-cocktail"></i>
          </div>
          <div class="features-content">
            <h3>Healthy Drinks</h3>
            <p>
              Explore our refreshing and nutritious selection of health drinks.
            </p>
          </div>
        </div>
        <div class="single-features">
          <div class="icon">
            <i class="icofont-cup-cake"></i>
          </div>
          <div class="features-content">
            <h3>Flavorous Sweets</h3>
            <p>
              Our sweets are made with the finest ingredients and are sure to
              satisfy your sweet tooth.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
