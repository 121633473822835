<div id="home" class="main-banner video-banner">
  <!-- FIXME: Disabling Video element until it is fully working -->
  <!-- <video
    autoplay="autoplay"
    muted
    loop="true"
    playsinline
    class="video-background"
  >
    <source src="assets/video/video.mp4" type="video/mp4" />
  </video> -->
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="main-banner-content">
          <h1>Istanbul Kebab & Pizza</h1>
          <h2>The best kebab and pizza place in Bellmere</h2>
          <p class="text">
            We serve the best kebabs and pizzas in Bellmere. Our food is
            prepared with the freshest ingredients and cooked to perfection.
            Whether you're in the mood for a delicious kebab or a mouth-watering
            pizza, we have something for everyone. Come and visit us today and
            experience the taste of Istanbul.
          </p>
          <div class="button-group">
            <app-order-now-button></app-order-now-button>
            <app-table-reservation-button></app-table-reservation-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="down_arrow">
    <a href="#about" class="scroll_down" aria-label="Scroll Down"></a>
  </div>
</div>
