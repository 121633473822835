<div id="offer" class="offer-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="text">Amazing Offers</span>
      <h2>Our Special Deals</h2>
    </div>
    <div class="offer-slides">
      <owl-carousel-o [options]="offerSlides" *ngIf="offers.length">
        <ng-template carouselSlide *ngFor="let offer of offers">
          <div class="single-offer">
            <img [src]="offer.image" [alt]="offer.title" />
            <div *ngIf="offer.offer_discount" class="offer-content">
              <h3>
                <span>{{ offer.offer_discount }}</span> {{ offer.title }}
              </h3>
              <p>{{ offer.description }}</p>
              <app-order-now-button></app-order-now-button>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
