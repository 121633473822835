<div id="about" class="story-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="story-image">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img1.jpg" alt="image" />
              <img src="assets/img/about/about-img2.jpg" alt="image" />
            </div>
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img3.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="story-content">
          <div class="section-title">
            <span class="text">About us</span>
            <h2>We have best <span>kebabs & pizza</span></h2>
          </div>
          <p>
            Welcome to Istanbul Kebab & Pizza, the best kebab and pizza place in
            Bellmere. We serve the best kebabs and pizzas in Bellmere. Our food
            is prepared with the freshest ingredients and cooked to perfection.
            Whether you're in the mood for a delicious kebab or a mouth-watering
            pizza, we have something for everyone. Come and visit us today and
            experience the taste of Istanbul.
          </p>
          <p>
            Our mission is to provide our customers with the best kebabs and
            pizzas in Bellmere. We take pride in our food and are committed to
            using only the freshest ingredients. Our kebabs are made with
            high-quality meat and our pizzas are topped with the finest
            ingredients. We believe that good food should be enjoyed by
            everyone, which is why we offer a wide range of options to suit all
            tastes.
          </p>
          <br />
          <p>Quality, you can trust</p>
          <div class="quote-info">
            <span> - The Istanbul Kebab & Pizza Team</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
